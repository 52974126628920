import React from "react";
import StarField from "../components/starfiels";
import Typed from "react-typed";

// images
import TwitterIcon from "../assets/images/twitter.png";
// import DiscordIcon from "../assets/images/discord.png";
import GitbookIcon from "../assets/images/gitbook.png";
import GithubIcon from "../assets/images/github.png";
import LogoImage from "../assets/images/official-white-horizontal.png";

const ComingSoon = () => {
  return (
    <div className="page-coming-soon">
      <StarField />
      <div className="page-coming-soon__title">
        <img src={LogoImage} alt="logo" />
      </div>
      <div className="page-coming-soon__text-1">
        <Typed
          strings={[
            "Decentralized Stablecoin Lending and Leveraged Yield Farming Protocol."
          ]}
          typeSpeed={80}
        />
      </div>
      <div className="page-coming-soon__text-2">
        {/* <Typed
          strings={["Coming soon to Avalanche"]}
          typeSpeed={80}
          backSpeed={20}
          loop

        /> */}
        Coming soon to Avalanche
      </div>
      <div className="page-coming-soon__social-icons">
        <a
          className="page-coming-soon__social-icons__1"
          href="https://twitter.com/cygnusdao"
          rel="noreferrer"
        >
          <img src={TwitterIcon} alt="twitter-icon" />
        </a>
        {/* <a
          className="page-coming-soon__social-icons__2"
          href="https://discord.gg/cygnus-terminal-dao"
          rel="noreferrer"
        >
          <img src={DiscordIcon} alt="discord-icon" />
        </a> */}
        <a
          className="page-coming-soon__social-icons__3"
          href="https://docs.cygnusdao.finance/"
          rel="noreferrer"
        >
          <img src={GitbookIcon} alt="gitbook-icon" />
        </a>
        <a
          className="page-coming-soon__social-icons__4"
          href="https://github.com/cygnusdao"
          rel="noreferrer"
        >
          <img src={GithubIcon} alt="github-icon" />
        </a>
      </div>
    </div>
  );
};

export default ComingSoon;
