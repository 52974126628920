import React from "react";
import { ReactNebula } from "@flodlc/nebula";

const NebulaMobile = () => {
  return (
         <ReactNebula config={{
               starsCount: 200,
           starsRotationSpeed: 1,
           nebulasIntensity: 7,
           cometFrequence: 100,
           sunScale: 2,
           planetsScale: 4,
           solarSystemOrbite: 110,
           solarSystemSpeedOrbit: 1
           }}/>
  );
};

export default NebulaMobile;
