import React from "react";
import { useRef, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

// images
import LogoImage from "../assets/images/cygnus-white.png";
import LogoImage2 from "../assets/images/cygnus-title.png";
import RocketImage from "../assets/images/rocket.png";
import StarknetLogo from "../assets/images/starknet-icon.webp";
import EthLogo from "../assets/images/eth-logo.png";

// import GovernanceTokenImage from "../assets/images/cyx.png";
import TwitterIcon from "../assets/images/twitter.png";
import GitbookIcon from "../assets/images/gitbook.png";
import GithubIcon from "../assets/images/github.png";
import DiscordIcon from "../assets/images/discord.png";
import StructureImage from "../assets/SchemeOP1.png";

import Nebula from "../components/nebula.js";
import NebulaMobile from "../components/nebulamobile.js";

import { ethers } from "ethers";
import hangarAbi from "../factory.json";
import cygAbi from "../cygToken.json";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Home = () => {
  const [hamburgerStatus, setHamburgerStatus] = useState(false);
  const [tvl, setTvl] = useState(0);
  const [supply, setSupply] = useState(0);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const toggleVisible = () => {
    if (null !== sidebarRef.current) {
      if (hamburgerStatus) {
        setHamburgerStatus(false);
        sidebarRef.current.style.transform = "scaleX(0)";
      } else {
        setHamburgerStatus(true);
        sidebarRef.current.style.transform = "scaleX(1)";
      }
    }
  };

  const getTvl = async () => {
    const providers = [
      "https://arb1.arbitrum.io/rpc",
      "https://polygon-rpc.com",
      "https://zkevm-rpc.com",
    ];
    const hangarAddress = "0x8796747946871B6b8ea495CCE8d7814b17959296";
    let tvlF = 0;

    for (let i = 0; i < providers.length; i++) {
      const prov = new ethers.providers.JsonRpcProvider(providers[i]);
      const hangar = new ethers.Contract(hangarAddress, hangarAbi, prov);
      const _tvl = await hangar.cygnusTvlUsd();
      tvlF += +_tvl / 1e6;
    }
    setTvl(+tvlF);
  };

  const getSupply = async () => {
    const providers = [
      "https://arb1.arbitrum.io/rpc",
      "https://polygon-rpc.com",
      "https://zkevm-rpc.com",
    ];

    const cyg = [
      "0x05eaea39f69b24f8f2da13af2d8ee0853889f2a8",
      "0xc553a5a789f1bb56a72847b3fda1de3e16e6763f",
      "0x91328602f85c24dfC6b8AE43A865679957d4eCAe",
    ];

    let supplyL = 0;

    for (let i = 0; i < providers.length; i++) {
      const prov = new ethers.providers.JsonRpcProvider(providers[i]);
      const cygtoken = new ethers.Contract(cyg[i], cygAbi, prov);
      const _tvl = await cygtoken.totalMinted();
      supplyL += +_tvl / 1e18;
    }

    setSupply(+supplyL - 750000);
  };

  useEffect(() => {
    getSupply();
    getTvl();
  }, []);

  return (
    <div className="page-home">
      <div className="page-home__nebula">
        {isMobile ? <NebulaMobile /> : <Nebula />}
      </div>
      <div className="scroll-panel">
        <div className="page-home__container" id="top">
          <div className="page-home__container__header">
            <div className="page-home__container__header__logo">
              <a href="https://cygnusdao.finance">
                <img src={LogoImage} alt="logo" />
              </a>
            </div>
            <div className="page-home__container__header__button">
              <a
                href="https://app.cygnusdao.finance/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="page-home__container__header__button__connect-button">
                  Terminal
                </button>
              </a>
            </div>
            <div
              className={
                "page-home__container__header__hamburger " +
                (hamburgerStatus ? "active" : "")
              }
              onClick={toggleVisible}
            >
              <span className="page-home__container__header__hamburger__bar" />
              <span className="page-home__container__header__hamburger__bar" />
              <span className="page-home__container__header__hamburger__bar" />
            </div>
            <div
              className="page-home__container__header__sidebar"
              ref={sidebarRef}
            >
              <div>
                <a
                  href="https://docs.cygnusdao.finance"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={toggleVisible}
                >
                  {"Docs"}
                </a>
              </div>
              <div className="page-home__container__header__sidebar__button">
                <a
                  href="https://app.cygnusdao.finance/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="page-home__container__header__sidebar__button__connect-button">
                    Terminal
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="page-home__container__1">
            <div className="page-home__container__1__title">
              <img src={RocketImage} alt="logo" />
              <img src={LogoImage2} alt="logo" />
            </div>
            <div className="page-home__container__1__texts">
              <div className="page-home__container__1__texts__text-1">
                STABLECOIN LENDING AND LEVERAGE TRADING PROTOCOL FOR LIQUIDITY
                PROVIDERS
              </div>
              <br />
              <div className="page-home__container__1__texts__text-2">
                <div style={{ whiteSpace: "nowrap" }}>
                  Coming soon to{" "}
                  <span style={{ textShadow: "0 0 20px #fff", color: "#fff" }}>
                    {" "}
                    EVMs
                  </span>{" "}
                  and{" "}
                </div>
                <img src={StarknetLogo} alt="logo" />
                <span style={{ textShadow: "0 0 20px #fff", color: "#fff" }}>
                  <div>Starknet</div>
                </span>
              </div>
            </div>
            <div className="page-home__container__1__btns">
              <a
                href="https://app.cygnusdao.finance/"
                rel="noopener noreferrer"
                target="_blank"
                style={{ borderRadius: "11px" }}
              >
                <div
                  className="page-home__container__1__btns__btn"
                  style={{ borderRadius: "11px" }}
                >
                  Enter APP
                </div>
              </a>
              <a
                href="https://docs.cygnusdao.finance/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="page-home__container__1__btns__btn">
                  Learn More
                </div>
              </a>
            </div>
            <div className="page-home__container__1__links">
              <a
                className="page-home__container__1__links__link"
                href="https://twitter.com/cygnusdao"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={TwitterIcon}
                  alt="twitter-icon"
                  width="45px"
                  height="45px"
                />
              </a>
              <a
                className="page-home__footer__container__links__link"
                href="https://discord.gg/8P9vWDUc2x"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={DiscordIcon}
                  alt="discord-icon"
                  width="45px"
                  height="45px"
                />
              </a>
              <a
                className="page-home__container__1__links__link"
                href="https://docs.cygnusdao.finance/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={GitbookIcon}
                  alt="gitbook-icon"
                  width="45px"
                  height="45px"
                />
              </a>
              <a
                className="page-home__container__1__links__link"
                href="https://github.com/cygnusdao"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={GithubIcon}
                  alt="github-icon"
                  width="45px"
                  height="45px"
                />
              </a>
            </div>
            <div className="page-home__container__1__infos">
              <div className="page-home__container__1__infos__info">
                <div>Total Supply</div>
                <div>40,000,000 CYG</div>
              </div>
              <div className="page-home__container__1__infos__info">
                <div>Cygnus TVL</div>

                <div>
                  {tvl === 0 ? (
                    <Skeleton
                      baseColor="#ebebeb"
                      highlightColor="#444"
                      width="25%"
                      style={{ opacity: "0.5" }}
                    />
                  ) : (
                    `$ ${tvl.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  )}
                </div>
              </div>
              <div className="page-home__container__1__infos__info">
                <div>Chains deployed</div>
                <div>
                  {tvl === 0 ? (
                    <Skeleton
                      baseColor="#ebebeb"
                      highlightColor="#444"
                      width="25%"
                      style={{ opacity: "0.5" }}
                    />
                  ) : (
                    "3"
                  )}
                </div>
              </div>
              <div className="page-home__container__1__infos__info">
                <div>Circulating CYG Supply</div>
                <div>
                  {tvl === 0 ? (
                    <Skeleton
                      baseColor="#ebebeb"
                      highlightColor="#444"
                      width="25%"
                      style={{ opacity: "0.5" }}
                    />
                  ) : (
                    `${supply.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  )}
                </div>
              </div>
              {/*
              <div className="page-home__container__1__infos__info">
                <div>xCYG Index</div>
                <div>1.00</div>
              </div>
                */}
            </div>
          </div>

          <div className="page-home__container__2">
            <div className="page-home__container__2__title">LATEST NEWS</div>
            <div className="page-home__container__2__articles">
              <div className="page-home__container__2__articles__article">
                <div className="page-home__container__2__articles__article__title">
                  <a
                    href="https://cygnusdao.medium.com/cygnusv2-core-part-1-uniswaps-permit2-78d979d275c"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    CYGNUS IMPLEMENTATION OF UNISWAP'S PERMIT2
                  </a>
                </div>
                <div className="page-home__container__2__articles__article__date">
                  19 June 2023
                  <div className="page-home__container__2__articles__article__readmore">
                    <a
                      href="https://cygnusdao.medium.com/cygnusv2-core-part-1-uniswaps-permit2-78d979d275c"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Read more...
                    </a>
                  </div>
                </div>
              </div>
              <div className="page-home__container__2__articles__article">
                <div className="page-home__container__2__articles__article__title">
                  <a
                    href="https://cygnusdao.medium.com/cygnuscore-smart-contracts-walkthrough-infinite-lending-pools-c44a8f2de512"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    SMART CONTRACT WALKTHROUGH - INFINITE LENDING POOLS
                  </a>
                </div>
                <div className="page-home__container__2__articles__article__date">
                  11 August 2022
                  <div className="page-home__container__2__articles__article__readmore">
                    <a
                      href="https://cygnusdao.medium.com/cygnuscore-smart-contracts-walkthrough-infinite-lending-pools-c44a8f2de512"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Read more...
                    </a>
                  </div>
                </div>
              </div>
              <div className="page-home__container__2__articles__article">
                <div className="page-home__container__2__articles__article__title">
                  <a
                    href="https://cygnusdao.medium.com/cygnus-an-inside-look-at-the-first-stablecoin-leveraged-yield-farming-protocol-in-avalanche-a0d50952bff4"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    AN INSIDE LOOK AT CYGNUS - PART III
                  </a>
                </div>
                <div className="page-home__container__2__articles__article__date">
                  25 May 2022
                  <div className="page-home__container__2__articles__article__date_readmore">
                    <a
                      href="https://cygnusdao.medium.com/cygnus-an-inside-look-at-the-first-stablecoin-leveraged-yield-farming-protocol-in-avalanche-a0d50952bff4"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Read more...
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-home__container__3">
            <div className="page-home__container__3__title" id="about">
              ABOUT CYGNUS
            </div>
            <div className="page-home__container__3__text">
              CYGNUS is a decentralized stablecoin lending and leverage trading
              protocol designed specifically for{" "}
              <strong>Liquidity Providers</strong>.{" "}
              <strong>There are $0 deposit, withdrawal or borrow fees</strong>.
              The protocol is built for maximum capital efficiency for
              stablecoins and liquidity providers, not to hit users with hidden
              or ridiculous fees.
            </div>
            <div className="page-home__container__3__content">
              <div className="page-home__container__3__content__text">
                <strong>Lenders </strong>supply their stablecoins to isolated
                lending pools (ie ETH/BTC on UniswapV3) and earn interests based
                on that specific pools's APY, as opposed to the fixed interest
                rate model, without the risk of impermanent loss or
                liquidations.
                <br />
                <br />
                <strong>Borrowers</strong> on the other hand use their liquidity
                as collateral to borrow or leverage stablecoins. If the borrower
                chooses to leverage, Cygnus automatically sells the stablecoins
                to purchase more liquidity. The protocol then adds the new
                liquidity to the borrower's position, while autocompounding
                their leveraged rewards and decreasing the debt ratio of all
                borrowers. All without them having to leave the pool they are
                in.
              </div>
              {/*
              <div className="page-home__container__3__content__pool">
                <div className="page-home__container__3__content__pool__header">
                  <div className="page-home__container__3__content__pool__header__left">
                    <div className="page-home__container__3__content__pool__header__left__logo">
                      <img src={TraderJoeImage} alt="traderjoe" />
                    </div>
                    <div className="page-home__container__3__content__pool__header__left__title">
                      Trader Joe
                    </div>
                  </div>
                  <div className="page-home__container__3__content__pool__header__right">
                    <div className="page-home__container__3__content__pool__header__right__title">
                      JOE
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__logo">
                      <img src={TraderJoeImage} alt="traderjoe" />
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__title">
                      AVAX
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__logo">
                      <img src={AvaxImage} alt="avax" />
                    </div>
                  </div>
                </div>
                <div className="page-home__container__3__content__pool__body">
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>TVL</div>
                    <div>$32.30 M</div>
                  </div>
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>Volume(24H)</div>
                    <div>$4.15 M</div>
                  </div>
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>APR</div>
                    <div
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationStyle: "solid",
                      }}
                    >
                      34.59%
                    </div>
                  </div>
                </div>
                <div className="page-home__container__3__content__pool__footer">
                  Cygnus APY 163%
                </div>
              </div>
              <div className="page-home__container__3__content__pool">
                <div className="page-home__container__3__content__pool__header">
                  <div className="page-home__container__3__content__pool__header__left">
                    <div className="page-home__container__3__content__pool__header__left__logo">
                      <img src={PangolinImage} alt="traderjoe" />
                    </div>
                    <div className="page-home__container__3__content__pool__header__left__title">
                      Pangolin
                    </div>
                  </div>
                  <div className="page-home__container__3__content__pool__header__right">
                    <div className="page-home__container__3__content__pool__header__right__title">
                      JOE
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__logo">
                      <img src={TraderJoeImage} alt="traderjoe" />
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__title">
                      AVAX
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__logo">
                      <img src={AvaxImage} alt="avax" />
                    </div>
                  </div>
                </div>
                <div className="page-home__container__3__content__pool__body">
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>TVL</div>
                    <div>$32.30 M</div>
                  </div>
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>Volume(24H)</div>
                    <div>$4.15 M</div>
                  </div>
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>APR</div>
                    <div
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationStyle: "solid",
                      }}
                    >
                      34.59%
                    </div>
                  </div>
                </div>
                <div className="page-home__container__3__content__pool__footer">
                  Cygnus APY 163%
                </div>
              </div>
              <div className="page-home__container__3__content__pool">
                <div className="page-home__container__3__content__pool__header">
                  <div className="page-home__container__3__content__pool__header__left">
                    <div className="page-home__container__3__content__pool__header__left__logo">
                      <img src={SushiSwapImage} alt="traderjoe" />
                    </div>
                    <div className="page-home__container__3__content__pool__header__left__title">
                      Sushiswap
                    </div>
                  </div>
                  <div className="page-home__container__3__content__pool__header__right">
                    <div className="page-home__container__3__content__pool__header__right__title">
                      JOE
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__logo">
                      <img src={TraderJoeImage} alt="traderjoe" />
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__title">
                      AVAX
                    </div>
                    <div className="page-home__container__3__content__pool__header__right__logo">
                      <img src={AvaxImage} alt="avax" />
                    </div>
                  </div>
                </div>
                <div className="page-home__container__3__content__pool__body">
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>TVL</div>
                    <div>$32.30M</div>
                  </div>
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>Volume(24H)</div>
                    <div>$4.15M</div>
                  </div>
                  <div className="page-home__container__3__content__pool__body__item">
                    <div>APR</div>
                    <div
                      style={{
                        textDecorationLine: "line-through",
                        textDecorationStyle: "solid",
                      }}
                    >
                      34.59%
                    </div>
                  </div>
                </div>
                <div className="page-home__container__3__content__pool__footer">
                  Cygnus APY 163%
                </div>
              </div>
              */}
            </div>
          </div>

          <div className="page-home__container__4">
            <div className="page-home__container__4__title">
              HOW CYGNUS WORKS
            </div>
            <div className="page-home__container__4__articles">
              <div className="page-home__container__4__articles__article">
                <div className="page-home__container__4__articles__article__title">
                  1. Under-collateralized loans
                </div>
                <div className="page-home__container__4__articles__article__text">
                  Cygnus allows liquidity providers to take out
                  under-collateralized loans using their liquidity as
                  collateral, without borrowers having to leave the pool they
                  are in. As they are borrowing stablecoins, they are
                  essentially going 'long' on their liquidity. <br />
                </div>
              </div>
              <div className="page-home__container__4__articles__article">
                <div className="page-home__container__4__articles__article__title">
                  2. Maximized profitability for LPs
                </div>
                <div className="page-home__container__4__articles__article__text">
                  Cygnus is designed to bring maximum profitablity to liquidity
                  providers and stablecoin lenders. The protocol uses ERC4626
                  vaults allowing anyone on the network to reinvest the pool's
                  rewards to buy more liquidity. This means that all shares in
                  the vault are slowly increasing in value due to trading fees
                  and reinvestment of pool rewards.
                </div>
              </div>
              <div className="page-home__container__4__articles__article">
                <div className="page-home__container__4__articles__article__title">
                  3. Increased stablecoin yields
                </div>
                <div className="page-home__container__4__articles__article__text">
                  As opposed to the fixed interest rate model typically found
                  across DeFi, Cygnus rewards stablecoin lenders in real
                  stablecoin yield with an interest rate model that is based
                  around the collateral's earnings (ie. the liquidity pool's
                  APR) which are amongst the highest in DeFi.
                </div>
              </div>
            </div>
            <div className="page-home__container__4__structure">
              <img src={StructureImage} alt="structure" />
            </div>
          </div>

          <div className="page-home__container__5">
            <div className="page-home__container__5__title">
              EARN WITH CYGNUS
            </div>
            <div className="page-home__container__5__texts">
              <div className="page-home__container__5__texts__text">
                With Cygnus users can finally go long on their liquidity or
                borrow a stable value to defend their position. Borrowers can
                deposit their liquidity as collateral to take out
                under-collateralized loans. This is possible because Cygnus has
                control of the liquidity (if a user leverages). If the user
                wants to remove their liquidity position they must de-leverage
                first and repay their loan.
              </div>
              <div className="page-home__container__5__texts__text">
                Users can borrow up to 13x their collateral value to enhance
                their position in the liquidity pool, while Cygnus reinvests all
                rewards from trading fees/liquidity mining to buy more liquidity
                for the users.
              </div>
            </div>
            <div className="page-home__container__5__table">
              <div className="page-home__container__5__table__body">
                <div className="page-home__container__5__table__body__column">
                  <div className="page-home__container__5__table__body__column__row">
                    Features
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Liquidity as Collateral
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Active Collateral
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Isolated Lending
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Auto-Compounding Vault Rewards
                  </div>
                </div>
                <div className="page-home__container__5__table__body__column">
                  <div className="page-home__container__5__table__body__column__row">
                    DeFi Lending Markets
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    *
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    *
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    *
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    *
                  </div>
                </div>
                <div
                  className="page-home__container__5__table__body__column"
                  style={{ textAlign: "center" }}
                >
                  <div className="page-home__container__5__table__body__column__row">
                    CYGNUS
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Use any supported pair to borrow
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Collateral is constantly increasing in value, earning
                    trading fees and mining rewards.
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Lenders can decide which liquidity pair to lend their
                    stablecoins in, reducing risk across the board
                  </div>
                  <div className="page-home__container__5__table__body__column__row">
                    Anyone on the network can compound the stablecoin and/or
                    collateral pool yields.
                  </div>
                </div>
              </div>

              <div className="page-home__container__5__table__footer"></div>
            </div>
          </div>
        </div>

        <div className="page-home__footer">
          <div className="page-home__footer__container">
            <div className="page-home__footer__container__logo">
              <a href="#top">
                <img src={LogoImage} alt="logo" />
              </a>
            </div>
            <div className="page-home__footer__container__text">
              ©2022 by Cygnus Finance. All rights reserved.
            </div>
            <div className="page-home__footer__container__links">
              <a
                className="page-home__footer__container__links__link"
                href="https://twitter.com/cygnusdao"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={TwitterIcon}
                  alt="twitter-icon"
                  width="45px"
                  height="45px"
                />
              </a>
              <a
                className="page-home__footer__container__links__link"
                href="https://discord.gg/8P9vWDUc2x"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={DiscordIcon}
                  alt="discord-icon"
                  width="45px"
                  height="45px"
                />
              </a>
              <a
                className="page-home__footer__container__links__link"
                href="https://docs.cygnusdao.finance"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={GitbookIcon}
                  alt="gitbook-icon"
                  width="45px"
                  height="45px"
                />
              </a>
              <a
                className="page-home__footer__container__links__link"
                href="https://github.com/cygnusdao"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={GithubIcon}
                  alt="github-icon"
                  width="45px"
                  height="45px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
