import React from "react";
import StarfieldAnimation from "react-starfield-animation";

const StarField = () => {
  return (
    <StarfieldAnimation
      numParticles={700}
      lineWidth={2.0}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: -10,
        background: "radial-gradient(ellipse at center, black, #781f77 150%)"
      }}
    />
  );
};

export default StarField;
