import React from "react";
import { ReactNebula } from "@flodlc/nebula";

const Nebula = () => {
  return (
    <ReactNebula
      config={{
        starsCount: 400,
        starsRotationSpeed: 1.4,
        nebulasIntensity: 5,
        cometFrequence: 100,
        sunScale: 7,
        planetsScale: 0,
        solarSystemOrbite: 190,
        solarSystemSpeedOrbit: 0,
      }}
    />
  );
};

export default Nebula;
